<template>
    <div class="footer-warpper">
        <div class="footer-box">
            <button class="btn manger-btn" :class="{disable: project_state !== 1}" @click="showManager" >管理项目</button>

            <button v-if="accumulative_number>0&& project_state == 2" :class="{'border': !accumulative_number}" class="btn manger-btn" @click="goWithdraw(projuuid)">快速提现</button>
            <button v-else  class="btn manger-btn" :class="{disable: project_state !== 1}" @click="shareProject">转发分享</button>
        </div>

        <ProjectManagerModal v-if="current_sub_popup === 'ProjectManagerModal' "/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ProjectManagerModal from '../ProjectManagerModal';
import saUtils from 'sensors-data-utils';

export default {
    name:'FooterBar',
    data () {
        return {}
    },
    computed: {
         ...mapState([
            'projuuid',
            'project_state',
            'accumulative_number',
            'is_manager',
            'current_sub_popup',
            'share_num'
        ]),
    },
    components: {
        ProjectManagerModal
    },

    methods: {
        showManager() {
            this.$store.commit('resetCurrentSubPopup', 'ProjectManagerModal');
        },
        shareProject() {
            if (this.project_state !== 1) return;
            saUtils.click('Button_Share');

            this.$store.commit('resetCurrentPopup', 'ProjectShareGuide');
        },
        goWithdraw(projuuid) {
            location.href = `${window.BasePath}fund/withdraw/entry?projuuid=${projuuid}`;
        }
    }
}
</script>

<style lang='less' scoped>
.footer-box {
    display: flex;
    color: #999;
}
.btn {
    display: block;
    width: 50%;
    font-size: 18px;
    text-align: center;
    border: none;
    background: #009BFF;
    position: relative;
    color: #fff;
    padding: 0;
    margin: 0;
}

.manger-btn {
    height: 50px;
    line-height: 50px;
}

.disable,
.manager_disable {
    background: #EFEFEF;
    box-shadow: none;
    color: #999999;
    pointer-events: none;
}

.border::after,
.btn:first-child::before {
    display: block;
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-box {
    box-shadow: 0 4px 30px 0 rgba(162,162,162,0.40);
}

.footer_disable {
    box-shadow: none;
    .btn {
        background: #EFEFEF;
        color: #999;
        pointer-events: none;
    }
    .btn:first-child::before {
        border-right: 1px solid rgba(255, 255, 255, 0.8);
    }
    .share-num {
        color: #999;
    }
}

.share-text,
.text-btn {
    line-height: 1;
}

.custome-btn {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-text {
    position: relative;
    width: 75px;
}

.share-num {
    display: block;
    background: #FFFFFF;
    border-radius: 4px 0 4px 0;
    font-size: 10px;
    color: #20ABFF;
    text-align: center;
    line-height: 1;
    padding: 2px 5px;
    top: -12px;
    right: -2px;
    position: absolute;
    min-width: 32px;
    transform: translateX(100%);
}
</style>
