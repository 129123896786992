<template>
    <div class="project-main" v-if="isLoad && project_title">
        <div class="detail-container" v-if="audit_state == 3">
            <HeaderSwiper v-if="images && images.length" />
            <ProjectBaseInfo />
            

            <ProjectStory v-if="introduction" class="mg-bt10" />
            <ProjectRealBox class="mg-bt10" />
            
            <ProjectPublicity class="mg-bt10" />
            <ProjectDynamic class="mg-bt10" />
            <ProjectSupport />

            <FooterBar />
            <ProjectShareGuide v-if="current_popup === 'ProjectShareGuide'" />
        </div>

        <div
            class="project-close"
            v-if="project_hide === 1 && !is_manager && audit_state == 3"
            @touchmove.prevent
        >
            <div class="content-desc">项目已关闭</div>
        </div>

        <div v-if="audit_state != 3" class="qrcode-box">
            <div class="mock"></div>
            <div class="content">
                <!-- <i @click="isShow = false"></i> -->
                <img src="./img/icon-shenheing.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getProjectDetail, getPublicityInfo } from './js/api';
import HeaderSwiper from './components/HeaderSwiper';
import ProjectRealBox from './components/ProjectRealBox';
import FooterBar from './components/FooterBar/index.vue';
import ProjectShareGuide from './components/ProjectShareGuide';
import ProjectManagerModal from './components/ProjectManagerModal';
import ProjectPublicity from './components/ProjectPublicity';
import ProjectSupport from './components/SupportLst/index.vue';
import { shareProj, tracking } from '@/common/js/mmcModule';
import saUtils from 'sensors-data-utils';

import Vue from 'vue';
import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

import './js/filter';
const pageKey = 'H5_JSB_DetailPage';

export default {
    name: 'projectDetail',
    components: { 
        HeaderSwiper,
        ProjectBaseInfo: () => import('./components/ProjectBaseInfo'),
        ProjectStory: () => import('./components/ProjectStory/index'),
        ProjectDynamic: () => import('./components/ProjectDynamic/index.vue'),
        
        ProjectPublicity,
        ProjectSupport,
        FooterBar,
        ProjectShareGuide,
        ProjectManagerModal,
        ProjectRealBox,
    },
    data() {
        return {
            isLoad: true, //项目信息接口

            supportList: [],
            loadBusy: true,
            page_next_id: '',
            nextPage: true,
            page_size: 10,
            projuuid: '',
            supportLoadMore: true,
        };
    },
    computed: {
        ...mapState([
            'project_title',
            'current_popup',
            'project_thumb',
            'project_state',
            'project_hide',
            'is_manager',
            'audit_state',
            'is_support',
            'introduction',
            'images',
            'jsbrnd',
        ]),
    },

    created() {
        const query = this.$route.query;
        this.projuuid = query.projuuid;
        this.getProject();
        this.getPublicity();
    },

    methods: {
        getProject() {
            showLoading('加载中...');

            getProjectDetail(this.projuuid)
                .then(res => {
                    hideLoading();

                    if (res.code === 0) {
                        document.title = res.data.title;
                        const data = res.data;
                        if (data.state === 2 && !data.is_manager) {
                            // alertMsg(
                            //     '该项目已暂停，自动为您跳转到爱心首页',
                            //     2000
                            // );

                            // setTimeout(() => {
                            //     location.href = `${window.BasePath}fund/home?mp=detail_zanting`;
                            // }, 2000);
                            // return;
                        }

                        this.$store.commit('initProject', res.data);
                    } else {
                        alertMsg(res.msg);
                    }

                    this.initShare();
                    this.isLoad = true;

                    /** 上报神策埋点 **/
                    this.reportData();
                })
                .catch(() => {
                    hideLoading();
                });
        },

        getPublicity() {
            getPublicityInfo(this.projuuid).then(res => {
                if (res.code === 0) {
                    this.$store.commit('initProjectPublicity', res.data);
                } else {
                    alertMsg(res.msg);
                }
            });
        },

        initShare() {
            // 分享代码
            let shareParams = {
                projuuid: this.projuuid,
                imgUrl: this.project_thumb,
                shareRecord: {
                    scene: pageKey,
                },
                success: () => {
                    this.$store.commit('resetCurrentPopup', '');
                },
            };
            shareProj.init(shareParams);
        },

        reportData() {
            tracking.init();

            saUtils.init(pageKey);
            saUtils.update({
                jsbrnd: this.jsbrnd,
                manager: this.is_manager ? 1 : 0,
                donated: this.is_support ? 1 : 0,
            });
            saUtils.pv();
        },
    },
};
</script>

<style lang="less" scoped>
.project-main {
    padding-bottom: 90px;
    background: #fff;
}
.mg-bt10 {
    margin-bottom: 8px;
}

.pd-rl {
    padding: 0 16px;
}

.project-close {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 99;
    .content-desc {
        width: 80%;
        padding: 30px 0;
        font-size: 18px;
        text-align: center;
        background: #fff;
        border-radius: 4px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}

.detail-container {
    background: #f7f7f7;
}
.qrcode-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    .mock {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
    }
    .content {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 264px;
        height: auto;
        i {
            display: inline-block;
            width: 33px;
            height: 33px;
            // background: url("./image/close.png");
            background-size: 100% 100%;
            float: right;
            transform: translateX(33px);
        }
        img {
        }
        p {
            font-size: 19px;
            color: #fff;
            margin-top: 20px;
        }
    }
}
</style>
