<template>
    <div class="footer-warpper" :class="{ ft_disable: customeState }">
        <div v-if="!is_support" class="footer-box">
            <button
                class="btn flex-row ptb-15 radius-4 border-1 mr-12 w-100"
                @click="shareProject"
            >
                <span class="lh-1 fw-600 fc-blue fc-gary">帮他转发</span>
                <span
                    class="share-num bg-blue fc-gary bg-gary"
                    v-if="share_num"
                    >{{ share_num }}</span
                >
            </button>

            <button
                class="btn flex-row ptb-15 bg-blue radius-4 flex-1"
                @click="supportPorject(projuuid)"
            >
                <span v-if="customeState" class="lh-1 fw-600 fc-white fc-gary"
                    >项目已结束</span
                >
                <span v-else class="lh-1 fw-600 fc-white fc-gary"
                    >我要捐款</span
                >
            </button>
        </div>

        <div v-else class="footer-box">
            <button
                class="btn flex-row ptb-15 radius-4 border-1 mr-12 w-100"
                @click="supportPorject(projuuid)"
            >
                <span v-if="customeState" class="lh-1 fw-600 fc-blue fc-gary"
                    >项目已结束</span
                >
                <span v-else class="lh-1 fw-600 fc-blue fc-gary">我要捐款</span>
            </button>

            <button
                class="btn flex-row ptb-15 bg-blue radius-4 flex-1"
                @click="shareProject"
            >
                <span class="lh-1 fw-600 fc-white fc-gary">帮他转发</span>
                <span
                    class="share-num bg-light-blue bg-gary fc-blue fc-gary"
                    v-if="share_num"
                    >{{ share_num }}</span
                >
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import saUtils from 'sensors-data-utils';

import utils from '@/common/js/utils';
const urlParams = utils.getRequestParams();

export default {
    name: 'CustomerFooterBar',
    data() {
        return {};
    },
    computed: {
        ...mapState([
            'project_state',
            'is_manager',
            'share_num',
            'projuuid',
            'is_support',
            'project_hide',
        ]),

        customeState() {
            console.log(this.project_state);
            return this.project_state !== 1 || this.project_hide === 1;
        },
    },

    methods: {
        shareProject() {
            if (this.customeState) return;
            saUtils.click('Button_Share');

            this.$store.commit('resetCurrentPopup', 'ProjectShareGuide');
        },

        supportPorject(projuuid) {
            if (this.customeState) return;

            const isMiniprogram = /miniProgram/.test(navigator.userAgent)
                ? 1
                : 0;

            saUtils.click('Button_Donate');
            if (isMiniprogram && urlParams.ch == 'petMiniProgram') {
                window.wx.miniProgram.navigateTo({
                    url: `/pay/fund/index?projectId=${projuuid}&ch=petFund`,
                });
            } else {
                let mp = urlParams.mp;
                let url= `${window.BasePath}fund/project/payment/?projuuid=${projuuid}`;
                if(mp){
                    url += `&mp=${mp}`;
                }
                location.href = url
            }
        },
    },
};
</script>

<style lang="less" scoped>
.footer-warpper {
    padding: 12px;
    box-shadow: 0 4px 15px 0 rgba(162, 162, 162, 0.4);
    background: #fff;
}
.footer-box {
    display: flex;
}
.btn {
    display: block;
    font-size: 18px;
    text-align: center;
    border: none;
    position: relative;
    padding: 0;
    margin: 0;
    background: none;
    overflow: hidden;
}

.share-num {
    display: block;
    border-radius: 0 0 0 4px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    height: 14px;
    line-height: 14px;
    padding: 0 4px;
    top: 0;
    right: 0;
    position: absolute;
    min-width: 25px;
    font-weight: 600;
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-1 {
    flex: 1;
}

.border-1 {
    border: 1px solid #089fff;
}

.bg-blue {
    background: #089fff;
}
.fc-white {
    color: #fff;
}
.fc-blue {
    color: #089fff;
}
.fz-18 {
    font-size: 18px;
}
.fw-600 {
    font-weight: 600;
}
.radius-4 {
    border-radius: 4px;
}
.pd-15 {
    padding: 15px;
}

.ptb-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.plr-15 {
    padding-right: 15px;
    padding-left: 15px;
}

.lh-1 {
    line-height: 1;
}

.w-100 {
    width: 27.2vw;
}
.mr-12 {
    margin-right: 12px;
}

.relative {
    position: relative;
}

.bg-light-blue {
    background: #d0edff;
}

.ft_disable {
    .btn {
        background: #efefef;
        color: #999;
        pointer-events: none;
    }
    .border-1 {
        border: 1px solid #efefef;
    }
    .share-num {
        color: #999;
        background: #efefef;
    }
    .fc-gary {
        color: #999;
    }
    .bg-gary {
        background: #dfdfdf;
    }
}
</style>
