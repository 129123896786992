<template>
    <div class="base-info-box" :class="{ 'fold-content': showContent }">
        <h1
            v-if="baseInfo"
            class="head-info flex"
            @click="showHideContentHandler"
        >
            <div class="cell-lt flex">
                <img src="./img/info-icon.png" class="icon" />
                <span class="title">基本信息</span>
                <span class="project-status">{{
                    baseInfo ? '已审核' : '提交中'
                }}</span>
            </div>
            <i class="arrow-btn" />
        </h1>
        <template v-if="showContent">
            <div v-if="baseInfo" class="content bg">
                <div class="item-group" v-if="payee_info.payee_term == 1">
                    <div class="label">
                        收款人姓名：{{ payee_info.payee_name }}
                    </div>
                    <div class="info-detail">
                        <template>
                            <span class="item">身份证明已提交</span>
                        </template>
                    </div>
                </div>
                <div class="item-group" v-if="payee_info.payee_term == 2">
                    <div class="label">
                        对公收款账户名称：{{ payee_info.payee_name }}
                    </div>
                    <div class="info-detail">
                        <template>
                            <span class="item">社会组织法人登记证书已提交</span>
                        </template>
                    </div>
                </div>

                <p class="tips">
                    *该项目信息不属于慈善公开募捐信息，真实性由信息发布个人负责。
                </p>
            </div>
            <div v-else class="no-data bg">
                <img src="./img/no-data.png" />
                <span class="desc">资料验证提交中...</span>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'BaseInfoBox',
    data() {
        return {
            showContent: false,
        };
    },

    computed: {
        ...mapState(['patient_info', 'payee_info']),

        baseInfo() {
            return this.payee_info;
        },
    },

    methods: {
        showHideContentHandler() {
            this.showContent = !this.showContent;
        },
    },
};
</script>

<style lang="less" scoped>
.base-info-box {
    .flex {
        display: flex;
        align-items: center;
    }
    .head-info {
        width: 100%;
        font-size: 16px;
        color: #333;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 0.5px solid #f2f4f2;

        .title {
            font-weight: 600;
        }
    }

    .cell-lt {
        span {
            display: inline-block;
            line-height: 1;
        }
    }
    .arrorw-btn {
        background-image: url('./img/icon-triangle-down.png');
        transform: rotateZ(0deg);
    }

    .item-group {
        margin-bottom: 25px;
        text-align: left;
        .label {
            font-size: 14px;
            color: #666666;
            margin-bottom: 12px;
        }
        .item {
            font-size: 11px;
            color: #0099ff;
            line-height: 1;
            position: relative;
            padding-left: 16px;
            margin-left: 10px;
            &::before {
                content: '';
                display: block;
                width: 11px;
                height: 11px;
                background: url('./img/select-icon.png') no-repeat center;
                background-size: cover;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .item:first-child {
            margin-left: 0;
        }
    }

    .tips {
        font-size: 12px;
        color: #999999;
        text-align: left;
    }

    .icon {
        width: 20px;
        height: auto;
        margin-right: 12px;
        margin-top: -4px;
    }

    .project-status {
        color: #fff;
        background: #0099ff;
        padding: 3px 4px;
        line-height: 1;
        margin-left: 8px;
        font-size: 12px;
        border-radius: 2px;
        margin-top: -2px;
    }

    .arrow-btn {
        display: block;
        width: 12px;
        height: 12px;
        background-image: url('~@/assets/icon-triangle-down.png');
        background-size: cover;
    }

    .bg {
        background: #f7f7f7;
        border-radius: 4px;
        padding: 16px 12px;
    }

    .content {
        font-size: 12px;
    }

    .no-data {
        img {
            width: auto;
            height: 26px;
            margin: 0 auto 12px;
            display: block;
        }
        .desc {
            font-size: 14px;
            color: #0099ff;
            letter-spacing: 0;
            text-align: center;
            line-height: 1;
            font-weight: 600;
        }
    }
}

.fold-content {
    .head-info {
        border-bottom: none;
    }
    .arrow-btn {
        transform: rotate(-180deg);
    }
}
</style>
