<template>
        <SectionCard
            title="筹款公示"
            :right-btn-label="!is_manager ? '举报/质疑' : ''"
            @rightBtnClick="onRightBtnClick"
            class="project-publicity"
        >
        
            <PublisherCommitment />
            <p style="color: #666;font-size: 12px;text-align: left;padding: 12px 0px 0;">本平台为网络求助平台，本平台发布的求助项目性质非公益慈善性质</p>
            <PatientBaseInfo />

            <FundPublicity />

            <TransparencyPublicity />
    </SectionCard>
</template>

<script>
import { mapState } from 'vuex';
import SectionCard from '../SectionCard/index.vue';
import PublisherCommitment from './PublisherCommitment.vue';
import PatientBaseInfo from './PatientBaseInfo.vue';
import FundPublicity from './FundPublicity.vue';
import TransparencyPublicity from './TransparencyPublicity/index.vue';

export default {
    name:'ProjectPublicity',
    data () {
        return {}
    },
    computed: {
        ...mapState(['is_manager']),
    },
    components: {
        SectionCard,
        PublisherCommitment,
        PatientBaseInfo,
        FundPublicity,
        TransparencyPublicity
    },
    methods: {
        onRightBtnClick() {
            const { projuuid } = this.$route.query;
            this.$router.push({
                name: 'project-report',
                query: { projuuid }
            })
        },
    }
}
</script>

<style lang='less' scoped>
.card-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
</style>
