<template>
    <div class="section-card">
        <div class="card-header">
            <div class="card-title-wrapper">
                <img v-if="icon" v-lazy="icon" class="icon" />
                <p class="title">
                    {{ title }}
                </p>
                <p v-if="subTitle" class="sub-title">
                    {{ subTitle }}
                </p>
            </div>
            

            <div v-if="rightBtnLabel" class="title-eight-btn" @click="$emit('rightBtnClick')">
                <p class="label">
                    {{ rightBtnLabel }}
                </p>
                <i class="icon-more" />
            </div>
           
        </div>
        
        <div class="card-content">
           
            <slot />
           
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionCard',

    props: {
        // icon图片地址，本地资源使用require()获取
        icon: {
            type: String,
            default: '',
        },
        // 主标题
        title: {
            type: String,
            default: '',
        },
        // 副标题
        subTitle: {
            type: String,
            default: '',
        },
        // 右侧按钮文案
        rightBtnLabel: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="less" scoped>
.section-card {
    padding: 24px 16px;
    background-color: #ffffff;
    border-radius: 4px;
}

.card-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-items: center;
}

.card-title-wrapper {
    display: flex;
    align-items: flex-end;

    .icon {
        height: 20px;
        width: auto;
        margin-right: 8px;
    }

    .title {
        flex-shrink: 0;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
    }

    .sub-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999;
        line-height: 16px;
        margin-left: 2px;
    }
}

.title-eight-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    .label {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
    }

    .icon-more {
        display: block;
        width: 12px;
        height: 12px;
        background: url('~@/assets/icon-tirangle-right.png') center / 12px no-repeat;
        margin-left: 2px;
    }
}

.card-content {
    margin-top: 16px;
}
</style>
