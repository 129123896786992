<template>
    <div class="base-info-box"
        v-if="withdraws_list && withdraws_list.length"
        :class="{ 'fold-content': showContent, 'list-fold': isFold }">

        <h1 class="head-info flex" @click="showHideContentHandler">
            <div class="cell-lt flex">
                <img src="./img/fund-icon.png" class="icon" />
                <span class="title">资金公示</span>
            </div>
            <i class="arrow-btn"></i>
        </h1>

        <div class="group-bg" v-if="showContent">
            <template v-if="withdraws_list && withdraws_list.length" >
                <div class="list-content">
                    <dl v-for="(item, index) in withdraws_list" :key="index" class="item-group">
                        <dt v-if="withdraws_list.length>1">#第{{ withdraws_list.length - index }}次提现</dt>
                        <dt v-else>#提现公示</dt>
                        <dd>
                            <div class="left">提现金额</div>
                            <div class="right">{{item.withdraw_amount | formatAmount}}元</div>
                        </dd>
                        <dd>
                            <div class="left">收款信息</div>
                            <div class="right">{{item.payee_account_info}}
                                <template v-if="item.validate_options && item.validate_options.length">
                                    <p class="info" v-for="(cItem, cIndex) in item.validate_options" :key="cIndex">{{cItem}}</p>
                                </template>
                            </div>
                        </dd>
                        <dd>
                            <div class="left">打款时间</div>
                            <div class="right">{{item.time | formateTime}}</div>
                        </dd>
                    </dl>
                </div>

                <div v-if="withdraws_list.length > 1" class="showMore" @click="showHideFromSecond">
                    {{ !isFold ? '查看全部' : '收起' }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState }  from 'vuex';
import utils from '@/common/js/utils'
export default {
    name: 'FundPublicity',
    data() {
        return {
            isFold: false,
            showContent: false,
        };
    },

    filters: {
        formateTime(value) {
            return utils.formatTimestapDate(value);
        },
        formatAmount(value) {
            return utils.formatAmount(value/100);
        }
    },

    computed: {
        ...mapState(['withdraws_list']),
    },
    methods: {
        showHideFromSecond() {
            this.isFold = !this.isFold;
        },
        showHideContentHandler() {
            this.showContent = !this.showContent;
        },
    },
};
</script>

<style lang="less" scoped>
.base-info-box {
    .flex {
        display: flex;
        align-items: center;
    }
    .head-info {
        font-size: 16px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 0.5px solid #f2f4f2;
        width: 100%;

        .title {
            font-weight: 600;
        }

        .icon {
            width: 21px;
            height: auto;
            margin-right: 12px;
            margin-top: -4px;
        }
        .project-status {
            display: block;
            color: #fff;
            background: #0099FF;
            padding: 3px 4px;
            line-height: 1;
            margin-left: 8px;
            font-size: 10px;
            border-radius: 2px;
            display: flex;
            align-items: center;
        }
        .arrow-btn {
            display: block;
            width: 12px;
            height: 12px;
            background-image: url('~@/assets/icon-triangle-down.png');
            background-size: cover;
        }

        .cell-lt {
            span {
                display: inline-block;
                line-height: 1;
            }
        }
    }

    .group-bg {
        background: #f7f7f7;
        border-radius: 4px;
        padding: 24px 12px;
    }

    .item-group:first-child {
        margin-top: 0;
    }

    .item-group {
        display: none;
        margin-top: 40px;
    }
    .item-group:first-child {
        display: block;
    }

    dt {
        color: #0099FF;
        font-weight: 700;
        margin: 0;
        text-align: left;
    }

    dd {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 12px 0 0;
        line-height: 1.5;
        font-size: 14px;

        &.p {
            line-height: 1.4;
        }
        .left {
            margin-right: 12px;
            color: #999;
        }
        .right {
            color: #333;
        }
    }

    .info {
        font-size: 12px;
        color: #0099FF;
        position: relative;
        padding-left: 9px;
        margin-top: 8px;
        line-height: 1;
        text-align: left;

        &::before {
            position: absolute;
            left: 0;
            top: 1px;
            content: '';
            width: 4px;
            height: 8px;
            border-bottom: 1px solid #0099FF;
            border-right: 1px solid #0099FF;
            display: block;
            transform: rotateZ(36deg);
        }
    }

    .showMore {
        font-size: 12px;
        color: #999;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        display: flex;
        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('~@/assets/icon-tirangle-right.png') center / 12px no-repeat;
            margin-left: 2px;
            transform: rotateZ(90deg);
        }
    }
}

.fold-content {
    .head-info {
        border-bottom: none;
    }
    .arrow-btn {
        transform: rotate(-180deg);
    }
}

.list-fold {
    .item-group {
        display: block;
    }
    .showMore {
        &::after {
            transform: rotateZ(-90deg);
        }
    }
}
</style>
