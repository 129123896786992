<template>
    <div class="supporters-warpper"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loadBusy"
        infinite-scroll-distance="200"
    >
        <div class="cell-label">
            <h2 class="support-num">爱心记录<span>（{{ support_num }}）</span></h2>
            <button v-if="is_manager" class="share-btn" :class="{disable: project_state !== 1}" @click="shareProject">立即转发</button>
        </div>

        <AutoThanks v-if="is_manager" />

        <div class="list-container" v-if="listData && listData.length" >
            <template v-for="(item, index) in listData">
                <AdvItem
                    v-if="AdIndexes.includes(index) && item.type === 'adv'"
                    :data="item"
                    :index="item.index"
                    :key="`${index}-Adv`"
                />
                <SupportItem v-else :key="`${index}-Support`" :item="item" />
            </template>

            <div v-if="['loading', 'noMore'].includes(load_list_status)" class="list-loading-status" >
                <p>{{ load_list_status === 'loading' ? '正在加载...':'只有这么多了～'}} </p>
            </div>
        </div>

        <div v-if="!supportList.length">
            <img src="./img/not-data.png" class="not-data" />
            <span class="not-data-desc">暂无支持记录</span>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import AdvItem from "./AdvItem";
import SupportItem from "./SupportItem";
import AutoThanks from "../AutoThanks";

import { getSupportList } from '../../js/api';
import advConfig  from './js/advConfig';
import utils from '@/common/js/utils';

import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

//配置广告
const urlParams = utils.getRequestParams();

export default {
    name:'Supporters',
    components: {
        AdvItem,
        SupportItem,
        AutoThanks,
    },
    data () {
        return {
            logo: require('./img/logo.png'),
            supportList: [],
            loadBusy: true,
            page_next_id: '',
            nextPage: true,
            page_size: 10,
            projuuid: '',
            supportLoadMore: true,

            // AdIndexes: [0],  // 广告位置
            // supportLength: [3], // 支持数显示对应该广告

            AdIndexes: [0, 4, 8],  // 广告位置
            supportLength: [3, 5, 10], // 支持数显示对应该广告
            advConfig,
            showSupportAdv: true,
        };
    },

    computed: {
        ...mapState([
            'support_num',
            'is_manager',
            'project_state',
            'is_support',
        ]),
        listData() {
            let list = this.supportList;
            if (!list) return [];

            return this.applyAdvSupportList();
        },
    },
    filters: {
        formartDate(value) {
            return utils.formatTimestapDate(value);
        }
    },
    created() {
        this.getSupportData();
    },
    methods: {
        loadMore() {
            if (!this.page_next_id) return;
            if (!this.supportLoadMore) return;
            this.supportLoadMore = false;

            this.loadBusy = true;
            this.load_list_status = 'loading';

            setTimeout(() => {
                this.getSupportData();
            }, 500);
        },
        getSupportData() {
            const params = {
                projuuid: urlParams.projuuid,
                page_next_id: this.page_next_id,
            }
            getSupportList(params)
            .then(res => {
                if (res.code == 0) {
                    let currentList = res.data;
                    if (currentList && currentList.length) {
                        this.supportList.push.apply(this.supportList, currentList);
                    }

                    if(!currentList || currentList.length < this.page_size) {
                        this.page_next_id = '';
                        this.load_list_status = 'noMore';
                    } else {
                        this.page_next_id = res.next;
                        this.load_list_status = 'more';
                    }
                } else {
                    alertMsg(res.msg);
                    this.load_list_status = 'noMore';
                }
                this.loadBusy = false;
                this.supportLoadMore = true;
            })
            .catch(err => {
                this.loadBusy = false;
            });
        },

        applyAdvSupportList() {
            let newList = JSON.parse(JSON.stringify(this.supportList));
            let list = this.supportList;

            const advConfig = this.advConfig;
            const adIndexes = this.AdIndexes;
            const supportLeng = this.supportLength;

            adIndexes.forEach((item, index) => {
                if(newList.length >= supportLeng[index] && newList.length < 11) {
                    list.splice(item, 0, advConfig[index])
                }
            });
            return list;
        },

        shareProject() {
            this.$store.commit('resetCurrentPopup', 'ProjectShareGuide');
        },
    },

}
</script>

<style lang='less' scoped>
.supporters-warpper {
    padding: 30px 15px 15px;
    background: #fff;
}

.list-container {
    margin: 0;
    padding: 0;
}

.cell-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.support-num {
    font-size: 20px;
    color: #333333;
    text-align: left;
    line-height: 1;
    display: flex;
    align-items: center;
    span {
        font-size: 17px;
        line-height: 1;
        margin-top: 2px;
    }
}

.share-btn {
    width: 70px;
    display: block;
    color: #fff;
    font-size: 12px;
    padding: 8px 9px;
    background-image: linear-gradient(90deg,#40B9FF,#009BFF);
    border-radius: 4px;
    margin-left: 10px ;
    box-shadow: 0 4px 30px 0 rgba(0,155,255,0.4);
    border: none;
}

.share-btn.disable {
    background: #EFEFEF;
    box-shadow: none;
    color: #999999;
    pointer-events: none;
}

.not-data {
    width: 200px;
    height: auto;
    display: block;
    margin: 0 auto;
}

.not-data-desc {
    font-family: PingFangSC-Light;
    font-size: 17px;
    color: #333333;
    text-align: center;
}

.list-loading-status {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: center;

    .load-more {
        color: #0099FF;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
    }

    .arrow-down {
        width: 9px;
        height: 9px;
        border-bottom: 1px solid #0099FF;
        border-right: 1px solid #0099FF;
        box-shadow: 0 0 0 lightgray;
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease;
        transform: rotate(45deg);
        top: -3px;
        position: relative;
        margin-left: 5px;
    }
}
</style>
