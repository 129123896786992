<template>
    <div class="confirm-modal">
        <div class="modal-body">
            <div class="cell-header" v-if="fundMoneyInfo">
                <h2 class="title">{{ fundMoneyInfo.title }}</h2>
                <span class="desc">{{ fundMoneyInfo.desc }}</span>
            </div>

            <div class="cell-footer">
                <button class="btn" @click="closeConfirmModal">取消</button>
                <button class="btn" @click="updateProject">{{fundMoneyInfo.btnText}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { updateProjectState } from '../js/api';

export default {
    name:'ConfirmModal',
    data () {
        return {
            isSubmite: false,
        }
    },
    computed: {
        ...mapState([
            'project_title',
            'projuuid',
            'project_state',
            'is_manager',
        ]),
        fundMoneyInfo() {
            let info = {
                title: '结束众筹',
                desc: '关闭项目后，将无法筹款哦',
                btnText: '确认关闭',
            }
            if(this.project_state === 2) {
                info = {
                    title: '开启众筹',
                    desc: '开启众筹后，尽量多多转发，获取更多支持，唤起好友关注哦！',
                    btnText: '确认开启',
                }
                return info;
            }
            return info;
        }
    },
    methods: {
        closeConfirmModal() {
            this.$emit('changeConfirmModal')
        },

        updateProject() {
            let projuuid = this.projuuid;

            // type 1: 开启项目 2:暂停项目
            let proejct_state = 2;
            let msg = '项目已暂停';
            if (this.project_state === 2) {
               proejct_state = 1;
               msg = '项目已开启'
            };

            this.closeConfirmModal();
            showLoading('正在更新...');
             const params = {
                projuuid,
                proejct_state,
            }
            if (this.isSubmite) return false;
            this.isSubmite = true;

            updateProjectState(params)
                .then(res => {
                    this.isSubmite = false;
                    hideLoading();

                    if (res.code === 0) {
                        //项目开启隐藏管理弹窗
                        this.$store.commit('resetCurrentSubPopup', '');

                        alertMsg(msg);
                        this.$store.commit('resetProjectState', proejct_state);
                    } else {
                        alertMsg(res.msg);
                    }
                })
                .catch(err => {
                    hideLoading();
                    this.isSubmite = false;
                })
        }
    }
}
</script>

<style lang='less' scoped>
.confirm-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.7);

    .modal-body {
        width: 270px;
        background: #fff;
        border-radius: 12px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        overflow: hidden;
    }
    .cell-header {
        text-align: center;
        padding: 18px 20px 30px;
    }
    .title {
        font-size: 17px;
        color: #030303;
        margin-bottom: 17px;
        margin: 0;
    }
    .desc {
        font-size: 13px;
        color: #030303;
    }

    .cell-footer {
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            border-top: 1px solid #E9E9E9;
            transform: scaleY(0.5);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
        }
        .btn {
            width: 50%;
            display: block;
            font-size: 16px;
            color: #009BFF;
            text-align: center;
            line-height: 1;
            padding: 13px 0;
            border: none;
            background: #fff;
            position: relative;
        }
        .btn:first-child::after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            border-right: 1px solid #E9E9E9;
            transform: scaleX(0.5);
            position: absolute;
            right: 0;
            top: 0;
        }
    }

}
</style>
