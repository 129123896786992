<template>
    <div class="project-main_banner">
        <div class="swiper-container banner-container" v-viewer>
            <div class="swiper-wrapper" >
                <div class="swiper-slide swiper-no-swiping"
                    v-for="(item, index) in coverImages"
                    :key="index">
                    <img v-lazy="item+'?x-image-process=style/love.png'" :data-preview="item+'?x-image-process=style/love.png'" class="preview-img" />
                    <i class="banner-bg" :style="{'backgroundImage':'url('+ item +'?x-image-process=style/love.png)'}"></i>
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Autoplay, Pagination]);

import '@/common/js/directives/useViewer';
export default {
    name: 'HeaderSwiper',
    data () {
        return {}
    },
    mounted() {
        this.initSwiper();
    },
    computed: {
        ...mapState(['images']),

        coverImages() {
            if (this.images && this.images.length) {
                return this.images.slice(0, 1);
            }
        }
    },

    methods: {
        initSwiper() {
            let config = {
                loop: false,
                autoplay: false,
                noSwiping: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                }
            }

            if (this.coverImages && this.coverImages.length > 1) {
                config.loop = true;
                config.autoplay = true;
                config.noSwiping = false;
            }

            const projectBanner = new Swiper('.banner-container', config);
        },
    }
}
</script>

<style lang='less' scoped>
.swiper-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	z-index: 1;
    .swiper-wrapper,
    .swiper-slide {
        width: 100vw;
        height: 55.33vw;
        max-height: 210px;
    }
    .swiper-slide {
        position: relative;
    }
}

.banner-bg {
    display: block;
    width: 100vw;
    height: 55.33vw;
    max-height: 210px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.swiper-pagination {
    justify-content: flex-end;
    display: flex;
    left: initial;
    right: 15px;

    /deep/.swiper-pagination-bullet-active {
        background: #fff !important;
    }
}

.preview-img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
</style>
