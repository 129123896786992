/**
 * 广告配置列表
 ***/

export default [
    {
        desc: '关爱流浪动物，我们一直在行动',
        img: require('../img/support-adv-06.png'),
        link:
            'https://m.jisuchou.net/app/live/activity/monthlycard/index?bi_cf=rocket.ct.lulu.yy.cwc_yk.20240604',
        type: 'adv',
        trackAdvIdx: 1,
        logoImg: require('../img/support-adv-06-logo.png'),
        logoTitle: '露露凯蒂@了您',
    },
    {
        desc: '留下爱心，带走幸运。点击下方快速为ta筹款',
        img: require('../img/support-adv-05.png'),
        link:
            'https://m.jisuchou.net/fund/publish/guide?rc=orion.ct.penny.tocreatepennytwo.20231108',
        type: 'adv',
        trackAdvIdx: 1,
    },
    {
        desc: '您的善举温暖了他人，邀您参与活动领好礼',
        img: require('../img/support-adv-04.png'),
        link:
            'https://m.qsebao.com/cbb/landpage?scene_name=pingan-property-pet-shjf--land-page&sku_name=pingan-property-pet-shjf&rc=orion.ct.baoxian.cwc_loverecord_3.20230524',
        type: 'adv',
        trackAdvIdx: 1,
    },
];
