<template>
    <div
        class="ad-orion-item"
        v-if="data"
        @click="onAdvClick(data.link, data.advIdx)"
    >
        <div class="cell-group" href="javascript:;">
            <img class="logo-icon" v-if="data.logoImg" :src="data.logoImg" />
            <img class="logo-icon" v-else src="~@/assets/logo.png" />
            <div class="cell-info">
                <span class="user_name" v-if="data.logoTitle">{{
                    data.logoTitle
                }}</span>
                <span class="user_name" v-else>宠物筹@了您</span>
                <span class="slogan">{{ data.desc }}</span>
            </div>
        </div>
        <img v-lazy="data.img" class="avatar" :key="index" />
    </div>
</template>

<script>
import saUtils from 'sensors-data-utils';

export default {
    name: 'AdvItem',
    props: {
        index: Number,
        data: Object,
    },
    data() {
        return {};
    },

    mounted() {
        saUtils.update({
            support_adv_index: this.data.trackAdvIdx,
        });
        saUtils.popup('H5_Pop_JSB_ADShow');
    },

    methods: {
        onAdvClick(link, advIdx) {
            saUtils.update({
                support_adv_index: advIdx,
            });
            saUtils.click('Button_AD');

            location.href = link;
        },
    },
};
</script>

<style lang="less" scoped>
.ad-orion-item {
    margin-bottom: 20px;
    position: relative;
    img {
        display: block;
    }
    .cell-group {
        display: flex;
        text-align: left;
        margin-bottom: 16px;
        span {
            display: block;
        }
        .user_name {
            font-size: 16px;
            font-weight: 600;
        }
        .slogan {
            color: #999999;
        }
    }

    .logo-icon {
        width: 40px;
        height: 40px;
        min-width: 40px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 12px;
    }
}
</style>
