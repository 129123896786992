<template>
    <div class="modal-share-style" @touchmove.prevent @click="closeShareModal">
        <div class="modal-body">
            <div class="share-guide"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectShareGuide',
    data () {
        return {}
    },
    methods: {
        closeShareModal() {
            this.$store.commit('resetCurrentPopup', '');
        },
    }
}
</script>

<style lang='less' scoped>
.modal-share-style {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);

	.modal-body {
		width: 100%;
		height: 100px;
		padding: 0;
		top: 20px;
		bottom: 0;
	}

    .share-guide {
        width: 285px;
		height: 158px;
        background: url('../img/share-guide-img.png') no-repeat;
		background-size: contain;
        position: absolute;
        top: 20px;
        right: 20px;
    }

	.mainIcon {
		width: 22px;
		height: 5px;
		display: inline-block;
		background: url('../img/icon.png') no-repeat;
		background-size: contain;
		position: relative;
		top: -6px;
		left: 4px;
	}

	p {
		font-size: 16px;
		color: #FFFFFF;
		margin: 0;
	}
	p:last-of-type {
		font-size: 18px;
		margin:5px 0 0px;
	}
	.shareIcon {
		width: 18px;
		height:18px;
		display: inline-block;
		background: url('../img/shareIcon.png') no-repeat;
		background-size: contain;
		margin: 0 10px 0 0;
	}
	.moments {
		width: 18px;
		height:18px;
		display: inline-block;
		background: url('../img/Moments.png') no-repeat;
		background-size: contain;
		margin: 0 10px 0 0;
	}
	.modal-content{
		background: none!important;
		position: relative;
		-webkit-background-clip: padding-box;
		background: #FFF;
		border-radius: 4px;
		max-width: 300px;
		text-align: center;
		margin: 100px auto;
	}
}
</style>
