<template>
  <div class="real_box">
      <div class="content">
          <div class="title_box">
              <div class="left"><i></i><h3>实名证实</h3><span>({{ confirm_num }})</span></div>
              <p class="more" v-if="confirms.length > 0" @click="goMore">查看更多</p>
          </div>
          <div class="list_real" v-if="confirms.length > 0">
              <template v-for="(item, index) in confirms">
                  <div class="list_box" v-if="index < 3">
                      <div class="avatar">
                          <img v-lazy="item.face_url" alt="">
                      </div>
                      <div class="real_box">
                          <p>{{item.content}}</p>
                          <div class="icon">
                              <span>{{item.relation | getStatus}}</span>
                              <i style="color: #999">{{ item.name }}</i></div>
                      </div>
                  </div>
              </template>
          </div>
          <div class="list_real" v-else>
              <img class="phone" src='./img/phone.png' alt="">
              <div class="real_dec">
                  <template v-if="!is_manager">
                      <p>了解情况帮TA证实</p>
                      <p>TA将获得更多的信任与支持！</p>
                  </template>
                  <template v-else>
                      <p>邀请好友帮忙证实</p>
                      <p>你将获得更多的信任与支持</p>
                  </template>
              </div>
          </div>
          <div class="real_btn" v-if="is_manager && confirmList.length == 0" @click="askShare">邀请好友证实</div>
          <div class="real_btn" v-if="!is_manager && !isReal" @click="helpReal">帮TA证实</div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getProjectConfirmList, checkConfirmList } from '../../js/api';
import utils from "@/common/js/utils";
const params = utils.getRequestParams();
export default {
    name: 'ProjectProved',
    data() {
        return {
            confirmList: [],
            isReal: false,
            total: 0
        }
    },
    computed: {
        ...mapState(['confirms', 'confirm_num', 'is_manager', 'projuuid', 'project_title', 'project_state',]),
        customeFooterEndState() {
            return !this.is_manager && this.project_state !== 1;
        }
    },
    filters: {
        getStatus(e){
           
            return ['', '朋友', '邻居', '同事', '亲戚', '医生', '其他'][e]
        }
    },
    created() {

    },
    mounted() {
        const { projuuid } = this.$route.query;
        const that = this;
        checkConfirmList(projuuid || this.projuuid).then(res => {
            if(res.code === 0) {
                const { certification, confirm } = res.data;
                that.isReal = confirm;
            }
        })
    },
    methods: {
        helpReal() {
            this.$router.push({
                path: '/fund/project/proveform',
                query: {
                    projuuid: this.projuuid
                }
            })
        },
        askShare() {
            if (this.customeFooterEndState) return;

            this.$store.commit('resetCurrentPopup', 'ProjectShareGuide');
        },
        goMore() {
            location.href = `${window.BasePath}fund/project/confirmlist?projuuid=${this.projuuid}&project_title=${this.project_title}`
        }
    }
}
</script>

<style lang="less" scoped>
.real_box {
    background: #fff;
    .content {
        padding: 25px 20px;
    }
    .title_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px 0;
        .left {
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                width: 25px;
                height: 18px;
                background: url(./img/real_icon.png);
                background-size: 100% 100%;
            }
            h3 {
                font-size: 20px;
                margin: 0 7px;
            }
            span {
                font-size: 17px;
                color: #999;
            }
        }
        .more {
            position: relative;
            font-size: 12px;
            color: #999;
            padding: 0 10px 0 0;
            &:after {
                content: '';
                display: inline-block;
                width: 7px;
                height: 7px;
                background: url(./img/right.png);
                background-size: 100% 100%;
                position: absolute;
                top: 4px;
                right: 0;
            }
        }
    }
    .list_real {
        .phone {
            width: 64px;
            height: 69px;
            margin: 10px 0;
        }
        .real_dec {
            margin: 10px 0 15px;
            color: #999;
            line-height: 1.7;
        }
    }
    .list_box {
        display: flex;
        align-items: center;
        padding: 15px 0 20px;
        border-bottom: 1px solid #e9e9e9;
        &:nth-last-child(1) {
            border-bottom: 0 solid transparent;
        }
        .avatar {
            flex-shrink: 0;
            width: 44px;
            height: 44px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 16px;
        }
        .real_box {
            p {
                text-align: left;
                font-size: 14px;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
            .icon {
                text-align: left;
                margin-top: 7px;
                font-size: 14px;
                span {
                    color: #0099FF;
                    font-weight: bold;
                }
                i {
                    font-style: normal;
                    margin-left: 5px;
                }
            }
        }
    }
    .real_btn {
        height: 44px;
        line-height: 44px;
        background: #D6F2FF;
        text-align: center;
        color: #0099FF;
        font-size: 18px;
        margin: 5px auto;
        border-radius: 4px;
        font-weight: bold;
    }
}
</style>
