import Vue from 'vue';
import utils from '@/common/js/utils';

/**
 * 将分转为元
 * @{param} t 金额分
 */
Vue.filter('centToYuan', (t) => {
    return Math.round(t / 100);
});

/**
 * 区别24小时前后的时间戳转化
 * @{param} t 精确到s的时间戳
 * ${return} 24小时内返回几小时前，24小时候返回时分秒
 */
Vue.filter('distinct24Hours', (t) => {
    const currentTimestamp = new Date().getTime() / 1000;
    const diffSec = Math.floor(currentTimestamp - t);
    if (diffSec > 60 * 60 * 24) {
        const dt = new Date(t * 1000);
        return utils.formatDate(dt, 'yyyy-MM-dd hh:mm:ss');
    } else {
        return utils.jsDateDiff(t);
    }
});

