<template>
    <div class="customer-footer-bar">
        <CustomerFooterBar v-if="!is_manager" />
        <ManageFooterBar v-else />
    </div>
</template>

<script>
import ManageFooterBar from './ManageFooterBar';
import CustomerFooterBar from './CustomerFooterBar';
import { mapState } from 'vuex';

export default {
    name:'FooterBarIndex',
    data () {
        return {}
    },
    computed: {
        ...mapState(['is_manager']),
    },
    components: {
        ManageFooterBar,
        CustomerFooterBar,
    },
}
</script>

<style lang='less' scoped>
.customer-footer-bar {
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
}
</style>
