<template>
    <div class="index">
        <h4><img src="./img/icon.png" />发起人承诺</h4>
        <p>发起人承诺所提交的文字与图片证明<strong>材料完全真实</strong>，无任何虚构事实及隐瞒真相的情况，基于本项目所筹款项将全部用于宠物救助，若有违反，发起人愿意承担全部法律责任。</p>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {};
    },
};
</script>

<style lang="less" scoped>
.index {
    background: url('./img/bg.png') left top repeat;
    background-size: 100% 100%;
    padding: 28px 31px;
    margin-bottom: 10px;
    text-align: left;
    border-radius: 4px;

    h4{
        display:flex;
        font-size: 18px;
        color: #333;
        justify-content: center;
        align-items: center;
        img{
            width: 18px;
            height: 22px;
            margin-right: 5px;
            margin-top: -3px;
        }
    }
    p{
        font-size: 12px;
        color: #333;
        line-height: 20px;
        margin-top: 15px;
        strong{
            color: #0099FF;
        }
    }
}
</style>
