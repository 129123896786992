<template>
    <div class="project-manager-modal" @touchmove.prevent>
        <div class="modal-content">
            <div class="modal-header">
                <!-- <div v-if="!accumulative_number && project_state === 1" class="cell-item"
                    @click="editProject">
                    <i class="status-icon edit-icon"></i>
                    <span class="title">编辑项目</span>
                </div> -->

                <!-- <div v-if="project_state === 2" class="cell-item" @click="showConfirmModal">
                    <i class="status-icon start-icon"></i>
                    <span class="title">开启众筹</span>
                </div> -->

                <div  class="cell-item" @click="showConfirmModal">
                    <i class="status-icon end-icon"></i>
                    <span class="title">结束众筹</span>
                </div>
            </div>
            <div class="modal-footer">
                <button class="cancel-btn" @click="cancelModal">取消</button>
            </div>
        </div>

        <ConfirmModal v-if="showConfirm" @changeConfirmModal="changeConfirmModal"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmModal from './ConfirmModal';

export default {
    name: 'ProjectManagerModal',
    data () {
        return {
            showConfirm: false
        }
    },
    components: {
        ConfirmModal
    },
    computed: {
        ...mapState([
            'accumulative_number',
            'project_state',
            'projuuid',
        ]),
    },

    methods: {
        cancelModal() {
            this.$store.commit('resetCurrentSubPopup', '');
        },
        showConfirmModal() {
            this.showConfirm = true;
        },
        changeConfirmModal() {
            this.showConfirm = false;
        },
        editProject() {
            const projuuid = this.projuuid;
            this.$router.push({
                path: '/fund/publish/edit',
                query: { 'projuuid': projuuid }
            });
        }
    }
}
</script>

<style lang='less' scoped>
.project-manager-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: rgba(0, 0, 0, 0.5);

    .modal-content {
        position: relative;
        background: #FFF;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        padding: 30px 20px;
        font-size: 18px;
        text-align: center;
        .title {
            font-size: 15px;
            color: #333333;
            letter-spacing: 0;
            text-align: center;
            line-height: 1;
        }
    }

    .cell-item {
        width: 50%;
    }
    .status-icon {
        width: 55px;
        height: 55px;
        display: block;
        margin: 0 auto 12px;
    }
    .edit-icon{
        background: url("../img/edit-icon.png")no-repeat;
        background-size: contain;
    }
    .end-icon {
        background: url("../img/end-icon.png")no-repeat;
        background-size: contain;
    }
    .start-icon {
        background: url("../img/start-icon.png")no-repeat;
        background-size: contain;
    }

    .modal-footer {
        padding: 0;
        position: relative;
        margin: 0 20px;
    }
    .modal-footer::before {
        display: block;
        content: "";
        width: 100%;
        height: 1;
        transform: scaleY(0.5);
        border-top: 1px solid #E9E9E9;
        position: absolute;
        top: 0;
        left: 0;
    }

    .cancel-btn {
        margin: 0;
        border: none;
        background: 0 0;
        padding: 12px;
        width: 100%;
        text-align: center;
        color: #333;
        font-size: 17px;
        box-shadow: none;
        position: relative;
        border-radius: 0 0 0 4px;
    }

}
</style>
