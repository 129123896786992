
import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = 'https://api.jisuchou.net/';

/**
 * 获取项目详情信息
 * @param {string} projuuid 项目uuid
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93456677
*/
const getProjectDetail = function (projuuid) {
    const url = `${baseurl}mmc/project/detail/${projuuid}`;
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

/**
 * 项目开启/暂停
 * @param {string} projuuid 项目uuid
 * @param {number} proejct_state 1: 开启 2:暂停
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93456690
*/
const updateProjectState = function (params) {
    const url = `${baseurl}mmc/project/turned`;
    return mmcAjax({
        method: 'POST',
        url,
        data: {
            'uuid': params.projuuid,
            'state': params.proejct_state,
        },
        crossDomain: true,
    })
}


/**
 * 项目证实列表
 * @param {string} projuuid 项目uuid
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93465605
*/
const getProjectConfirmList = function (projuuid) {
    const url = `${baseurl}mmc/project/confirm/list/${projuuid}`;
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

const checkConfirmList = function (projuuid) {
    const url = `${baseurl}mmc/project/confirm/check/${projuuid}`;
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

/**
 * 资金公示
 * @param {string} projuuid 项目uuid
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93464757
*/
const getPublicityInfo = function (projuuid) {
    const url = `${baseurl}mmc/project/raisefundsPromulgate/detail/${projuuid}`;
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}

/**
 * 项目支持记录
 * @param {string} projuuid 项目uuid
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93456711
*/
const getSupportList = function (params) {
    const { projuuid, page_next_id } = params;
    const url = `${baseurl}mmc/project/supports/${projuuid}?next=${page_next_id}`;

    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    });
}

/**
 * 项目支持记录
 * @param {string} projuuid 项目uuid
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93468505
*/
const getDynamicList = function (params) {
    const { projuuid, page } = params;
    const url = `${baseurl}mmc/project/dynamic/list/${projuuid}?page=${page}`;

    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    });
}

/**
 * 项目是否开启自动感谢
 * @param {string} projuuid 项目uuid
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93482193
*/

const updateAutoReply = function (params) {
    const url = `${baseurl}mmc/project/autoreply/update`;
    return mmcAjax({
        method: 'POST',
        url,
        data: params,
        crossDomain: true
    });
}

export {
    getProjectDetail,
    updateProjectState,
    getProjectConfirmList,
    checkConfirmList,
    getPublicityInfo,
    getSupportList,
    getDynamicList,
    updateAutoReply,
}
