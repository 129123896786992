<template>
    <div class="transparency">
        <div class="swiper-container content-data">
            <ul class="swiper-wrapper">
                <li class="swiper-slide swiper-no-swiping" v-for="(item, index) in list" :key="index">{{item}}</li>
                <!-- <li class="swiper-slide">社交网络熟人持续验证中</li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';
Swiper.use([Autoplay]);

export default {
    name: 'index',
    data: function () {
        return {
            rollIndex: 0,
            timer: null,
            list: [
                '社交网络熟人持续验证中',
                '宠物筹大数据持续验证中',
                '第三方银行资金专管中',
                '宠物筹反诈行动监督中'
            ]
        };
    },
    mounted() {
        this.initSwiper();
    },

    methods: {
        initSwiper() {
            new Swiper('.content-data', {
                direction: 'vertical',
                loop: false,
                noSwiping: true,
                autoplay: {
                    delay: 2000
                }
            });
        },
    }
};
</script>

<style lang="less" scoped>
.transparency {
    margin-top: 20px;
    width: 208px;
    height: 26px;
    position: relative;
    background-image: linear-gradient(270deg, #FFFFFF 0%, #D6F2FF 100%);
    border-radius: 25px 4px 4px 25px;
    padding-left: 32px;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 26px;
        height: 26px;
        background: url('../img/bank-icon.png') left top / 100% 100% no-repeat;
        z-index: 1;
    }
}
.content-data {
    overflow: hidden;
    border-radius: 4px;
    width: 185px;
    height: 26px;
    position: relative;
    color: #0099FF;
    ul {
        display: block;
        font-size: 14px;
        font-weight: 700;
        width: 100%;
        text-align: left;
        margin-top: 2px;

        li {
            height: 26px;
            line-height: 1;
            display: flex;
            align-items: center;
        }
    }
}
</style>
