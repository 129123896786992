<template>
    <div class="thank-reply">
        <div class="cell-hd flex">
            <div class="info flex"><img src="./img/msg-icon.png" />自动感谢</div>
            <div class="">
                <van-switch
                    :value="replySwitch"
                    active-color="#0BA0FF"
                    inactive-color="#E5E5E5"
                    @input="onInput"
                 />
            </div>
        </div>
        <span class="desc flex" @click="onCheckThanks">查看感谢语 <i class="arrow-icon"></i></span>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Switch, Dialog } from 'vant';
import { updateAutoReply } from '../../js/api';

Vue.use(Switch);
Vue.use(Dialog);

export default {
    name:'AutoThanks',
    data () {
        return {
            replySwitch: false,
        }
    },
    computed: {
        ...mapState(['is_auto_reply', 'projuuid'])
    },
    created() {
        console.log('created')
    },
    mounted() {
        console.log('mounted')
        if (this.is_auto_reply === 1) {
            this.replySwitch = true
        }
    },

    methods: {

        onInput() {
            let msg = {
                title: '关闭自动感谢',
                desc: '关闭自动感谢后，您将无法回复感谢爱心人士~'
            }
            if(!this.replySwitch) {
                msg = {
                    title: '开启自动感谢',
                    desc: '开启自动感谢后，系统将为您自动回复帮助您的每一位爱心人士~'
                }
            };
            Dialog.confirm({
                title: msg.title,
                message: msg.desc,
                cancelButtonColor: '#0099FF',
                confirmButtonColor: '#0099FF',
                messageAlign: 'left',
            }).then(() => {
                this.setConfig();
            })
            .catch(() => {

            });
        },
        setConfig() {
            const params = {
                is_auto_reply: this.is_auto_reply === 1 ? 2 : 1,
                uuid: this.projuuid
            }

            updateAutoReply(params)
            .then(() => {
                // 更新本地状态
                this.replySwitch = params.is_auto_reply === 1 ? true : false;
                this.$store.commit('resetProjectAutoReply', params.is_auto_reply)

                alertMsg('设置成功', 1000);

            }).catch(() => {
                console.log('err');
            })
        },
        onCheckThanks() {
            const { projuuid } = this.$route.query;

            this.$router.push({
                path: '/fund/project/reply',
                query: { projuuid }
            });
        }
    },
}
</script>

<style lang='less' scoped>
.thank-reply {
    background: #F7F7F7;
    border-radius: 4px;
    padding: 0 16px;
    margin-bottom: 24px;

    .flex {
        display: flex;
    }
    .cell-hd {
        font-size: 14px;
        color: #333333;
        justify-content: space-between;
        padding: 16px 0;
        border-bottom: 1px solid #E5E5E5;
    }
    .info {
        font-size: 14px;
        color: #333333;
        align-items: center;
        img {
            display: inline-block;
            width: auto;
            height: 20px;
            margin-right: 10px;
        }
    }
    .desc {
        padding: 15px 0;
        font-size: 12px;
        color: #999;
        text-align: center;
        line-height: 1;
        justify-content: center;

        .arrow-icon {
            width: 12px;
            height: 12px;
            background: url('./img/arror-rt-icon.png') no-repeat center;
            background-size: cover;
        }
    }
}

.van-switch {
    border: none;
    width: 35px;
    height: 20px;
    border-radius: 18px;
}

/deep/.van-switch__node {
    box-shadow: 0 0 10px rgba(0,0,0, 0.2);
    width: 18px;
    height: 18px;
    top: 50%;
    transform: translate3d(1px, -50%, 0);
}

/deep/.van-switch--on {
    .van-switch__node {
        transform: translate3d(16px, -50%, 0);
    }
}
</style>
