<template>
    <ul>
        <li class="cell-item" v-if="item">
            <div class="list_link">
                <div class="user_avatar" :class="{ 'active': item.mine}">
                    <img v-if="item.face_url" class="lazy-load icon" v-lazy="item.face_url">
                    <img v-else class="lazy-load icon" src="./img/logo.png">
                </div>

                <div class="cell-rt_hd">
                    <span class="user_name">{{ item.user_name || "爱心人士" }}</span>
                    <div class="cell-ct">
                        <div class="attach">{{ item.support_unix | formartDate }}</div>
                        <div class="money">支持了<span>{{ item.amount/100 }}</span>元</div>
                    </div>
                    <div class="support_text" v-if="item.comment">{{ item.comment }} </div>
                </div>
                 <div class="thank-msg" v-if="item.thank_reply">
                    <span>{{issuer_name}}：</span>{{item.thank_reply}}
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex';
import utils from '@/common/js/utils';
export default {
    name:'SupportItem',
    props: {
        item: Object
    },
    computed: {
        ...mapState(['issuer_name'])
    },
    data () {
        return {}
    },
    filters: {
        formartDate(value) {
            return utils.formatTimestapDate(value);
        }
    },
}
</script>

<style lang='less' scoped>
.cell-item {
    list-style: none;
    margin-bottom: 30px;
    padding-left: 40px;
    position: relative;

    .list_link {
        display: block;
    }
    .user_avatar {
        position: absolute;
        top: 2px;
        left: 0;
        width: 40px;
        height: 40px;
    }
    .active {
        &::after {
            display: block;
            content: "";
            width: 12px;
            height: 12px;
            background: url('./img/me-icon.png') no-repeat center;
            background-size: cover;
            position: absolute;
            right: 0;
            bottom: 2px;
            z-index: 10;
        }
        .icon {
            border: 1px solid #C6E8FF;
        }
    }
    .icon {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
    }

    .user_name {
        display: block;
        text-align: left;
        font-size: 17px;
        color: #333;
        font-weight: 600;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 140px;
    }

    .cell-ct {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .attach {
            font-size: 13px;
            color: #999;
            padding-top: 8px;
            line-height: 1;
        }

        .money {
            font-size: 13px;
            color: #009BFF;
            text-align: right;
            line-height: 1;
            flex-shrink: 0;

            span {
                display: inline-block;
                font-weight: 500;
                font-size: 24px;
                padding: 0 2px;
                position: relative;
                bottom: -1px;
                font-family: PingFangSC-Medium,PingFang SC;
            }
        }
    }

    .support_text {
        margin-top: 12px;
        word-break: break-all;
        display:-webkit-box;
        -webkit-line-clamp:5;
        -webkit-box-orient:vertical;
        overflow:hidden;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        line-height: 1;
        text-align: left;
    }

    .thank-msg {
        background: #F7F7F7;
        border-radius: 4px;
        font-size: 14px;
        color: #9B9B9B;
        padding: 15px 9px;
        text-align: left;
        position: relative;
        margin-top: 20px;

        &::after {
            position: absolute;
            left: 8px;
            top: -12px;
            content: "";
            display: block;
            width:0;
            height:0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid #F7F7F7;
        }
        span {
            color: #333;
        }
    }
}
.cell-rt_hd {
    padding-left: 15px;
}
</style>
